import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate hook
import HamburgerMenu from "../src/assests/hamburger.svg";
import PawLogo from "../src/assests/paypawlogo256.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileLines } from '@fortawesome/free-solid-svg-icons'
import { faClockRotateLeft } from '@fortawesome/free-solid-svg-icons'
import { faHouse } from '@fortawesome/free-solid-svg-icons'
import { faTwitter } from '@fortawesome/free-brands-svg-icons'
import { faTelegram } from '@fortawesome/free-brands-svg-icons'
import historySelected from "../src/assests/history-selected.svg";
import historyUnselected from "../src/assests/history-unselected.svg";
import homeSelected from "../src/assests/home-selected.svg";
import homeUnselected from "../src/assests/home-unselected.svg";
import orderSelected from "../src/assests/order-selected.svg";
import orderUnselected from "../src/assests/order-unselected.svg";


const NavigationBar = ({ navbarVisible, toggleNavbarVisibility }) => {
  const navigate = useNavigate();
  const [activeButton, setActiveButton] = useState("home"); // State to keep track of the active button
  const [totalOrders, setTotalOrders] = useState("0");

  const goToHome = () => {
    setActiveButton("home");
    navigate("/");
  };

  const goToHistory = () => {
    setActiveButton("history");
    navigate("/history-table");
  };

  const goToOrderStatus = () => {
    setActiveButton("status");
    navigate("/check-trade-id");
  };

  // Dynamic class for buttons to maintain hover state
  const getButtonClass = (buttonName) => {
    return `rounded p-2 flex items-center justify-start w-full md:w-45 gap-3 nav-hover ${
      activeButton === buttonName ? "nav-btn" : ""
    }`;
  };

  useEffect(() => {
    const fetchAllOrders = async () => {
      const response = await fetch("https://paypawcards.com/paypawbackend/get-total");
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      let total = Math.ceil(data.total/1000)*1000;
      let display = total.toString();
      display = display.substring(0,3) + "K"
      setTotalOrders(display)
    };

    fetchAllOrders();

  }, []);

  const getButtonText = (buttonName) => {
    return `nav-button-text ${activeButton === buttonName ? "nav-button-text-selected" : "nav-button-text-unselected"}`;
  };

  const getButtonImageHome = (buttonName) => {
    return activeButton === buttonName ? homeSelected : homeUnselected;
  };
  const getButtonImageHistory = (buttonName) => {
    return activeButton === buttonName ? historySelected : historyUnselected;
  };
  const getButtonImageStatus = (buttonName) => {
    return activeButton === buttonName ? orderSelected : orderUnselected;
  };
  
  return (
    <nav
      className={`bg-nav-bar text-white w-64 md:w-46 flex flex-col items-center p-12 space-y-6 h-screen justify-between absolute z-10 sm:z-0 sm:relative inset-y-0 left-0 border-solid border-r-2 border-border`}
      style={!navbarVisible ? { left: "-255px" } : { left: 0 }}
    > 
    
      <button className="block sm:hidden navbar-button" onClick={toggleNavbarVisibility}>
        <img src={HamburgerMenu} alt="Hamburger Menu Icon" />
      </button>
    
    
      <div className="flex flex-col items-center justify-center">
        <div className="flex items-center logo-frame">
          <img src={PawLogo} alt="paw logo" className="max-h-8"/>
          <span className="paypaw-text">Pay<i>Paw</i></span>
        </div>
        <h1 className="navigation-text">Navigation</h1>
        <div className="navigation-selection">
          <button className={getButtonClass("home")} onClick={goToHome}>
            <img src={getButtonImageHome("home")} alt="home"/>
            <span className={getButtonText("home")}>Home</span>
          </button>
          <button className={getButtonClass("history")} onClick={goToHistory}>
          <img src={getButtonImageHistory("history")} alt="history"/>
            <span className={getButtonText("history")}>History</span>
          </button>
          <button className={getButtonClass("status")} onClick={goToOrderStatus}>
          <img src={getButtonImageStatus("status")} alt="status"/>
            <span className={getButtonText("status")}>Check Order Status</span>
          </button>
        </div>
      </div>

      <div>
        <div className="grid grid-cols-3 p-4">
          <div className="flex justify-center">
            <a href="https://twitter.com/paypaw_cards" target="_blank" className="social-btn twitter-btn">
              <FontAwesomeIcon icon={faTwitter} className="icon" />
            </a>
          </div>
          <div className="flex justify-center">
            <a href="https://t.me/PayPawPortal" target="_blank" className="social-btn telegram-btn">
              <FontAwesomeIcon icon={faTelegram} className="icon" />
            </a>
          </div>
          <div className="flex justify-center">
            <a href="https://docs.paypaw.cards/" target="_blank" className="social-btn docs-btn">
              <FontAwesomeIcon icon={faFileLines} className="icon" />
            </a>
          </div>
        </div>
        <div className="flex flex-col items-center justify-center">
          <small className="text-center">
            ${totalOrders} in cards redeemed!
          </small>
        </div>
      </div>
    </nav>
  );
};

export default NavigationBar;
