import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom"; // Import useNavigate hook
import important from "../src/assests/i-icon.svg";

const DialogBox = () => {
  const navigate = useNavigate(); // Initialize the useNavigate hook

  const goToCreateYourCard = (cardType) => {
    navigate("/disclaimer", { state: { type: cardType } });
  };

  return (
    <div className="absolute bg-nav-bar flex justify-center items-center mx-8 sm:mx-0 rounded-lg gray-border w-96 md:w-607">
      <div className="text-white">
        <div className="flex flex-col items-center">
          {/* heading */}
          <div className="flex items-center justify-start p-4">
            <h2 className="text-2xl font-bold mb-3">
              Welcome to PayPaw!
            </h2>
          </div>
          {/* body */}
          <div className="flex flex-col items-center px-4 pb-4 w-96 md:w-607">
            <p className="pb-5">
              Purchase prepaid debit cards or gift cards with your crypto in just a few clicks.
            </p>

            <div className="flex items-center justify-center pb-5 w-96 md:w-607">
              <div className="warning-frame">
                <div className="ps-4 pt-3 flex items-center">
                  <span><img src={important} alt="important" className="max-h-6 warn-text"/></span><span className="warn-text ps-3">Own 1million+ $PAW?</span>
                </div>
                <div className="ps-11 flex items-start pb-2">
                  <span>Connect your wallet and recieve lower fees on every order!</span>
                </div>
              </div>
            </div>
            <button
              className="green-btn w-7/12"
              onClick={() => goToCreateYourCard("gift")}
            >
              Create your Gift Card →
            </button>
            <div className="pb-5"></div>
            <button
              className="pink-btn w-7/12"
              onClick={() => goToCreateYourCard("prepaid")}
            >
              Create your Prepaid Card →
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DialogBox;

