export async function authenticateWithSolana(publicKey) {
  try {
    const response = await fetch('https://paypawcards.com/paypawbackend/solana-sign-in', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ publicKey: publicKey, source: "paypaw" })
    });

    const data = await response.json();

    if (response.ok && data.accessToken) {
      localStorage.setItem('jwt', data.accessToken);
      return data.accessToken;
    } else {
      throw new Error(data.message || 'Authentication failed - no access token received');
    }
  } catch (error) {
    throw error;
  }
}